import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const description = "tio'nun dijital işbirliği için sunduğu hizmetler; dijital danışmanlık, branding, UX/UI, dijital ürün yönetimi."

const CaseIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const pages = data.allWpPage.nodes

  /*
  if (!pages.length) {
    return (
      <Layout>
        <Seo title="Markaların dijital partnerleri oluyoruz." at />
        <p>Case study sayfası bulunamadı.</p>
      </Layout>
    )
  }
  */

  return (
    <Layout navbarTheme="dark" className="bg-cream">
      <Seo title="Hizmetler" description={description}/>
         <div className="bg-gray relative py-10">
        <div className="container font-light text-2xl text-center mx-auto  lg:px-10 ">
            <h1 className="text-5xl p-10 font-bold text-blue">
            Hizmetler
            </h1>
            <p className=" mb-10 px-10 mx-auto  font-light max-w-lg">Ürünlerimizi kullanan D2C markaları dijital iş akışlarında hizmetlerimizle destekliyoruz.</p>  
            <div className="bg-white txt-center max-w-4xl p-20 mx-auto ">
                <h3 className="text-blue font-2xl p-10 ">1. Adım: Ücretsiz Danışmanlık</h3>
                <p className="max-w-lg mx-auto">Herhangi bir dijital çözüm önermeden önce iş akışınızı dinliyor, gerektiğinde yerinde gözlemleyip analiz ediyoruz. Bu görüşme olası adımlar atılmadan önce iki tarafın birbirini doğru anlamasını sağlıyor.</p>
                <div className="block py-5 mt-20 text-sm">
                    <a href="/digital-consultancy" className="bg-blue p-5 rounded py-2 text-white"  >Demo talebi oluştur</a>
                </div>
            </div>
            <div className="mx-auto p-20 text-center ">
                <span className="p-10"><StaticImage className="bounce" width={30} src="../images/arrow.png" alt="Before / After tio" /></span>
                <h3 className="text-blue text-xl py-10 lg:p-10 font-thin">ÇALIŞMAYA BAŞLAYALIM</h3>
            </div>
        </div>
    </div>

    <div className="container text-xl text-left mx-auto  lg:px-10 ">
            <ul className="flex flex-wrap">
                <li className="w-full lg:w-1/2 p-10 lg:p-20">
                  <h3 className="text-3xl font-bold text-blue py-5">Dijital Ürün Danışmanlığı</h3>
                  <p className="py-5 max-w-sm font-thin">
                      Her zaman var olan akışı müşterileriniz için daha iyi veya daha kolay hale getirecek teknolojik iyileştirmeler yapılabilir. 
                      Hedeflerinizi gerçekleştirebilmek için dijitalde neleri daha iyi yapabileceğinizi arıyor ve uygulanaibilir adımlar haline getiren bir <b>yol haritası</b> oluşturuyoruz.
                  </p>
                  <p className="py-5 max-w-sm font-thin">
                      Bu hizmet birlikte yürütülen bir atölye çalışması şeklinde verilir. Ekibinizle birlikte çalışır, gerekirse daha verimli çalışabilmek için üretim alanınızı ziyaret ederiz. Proje büyüklüğüne göre 4 saatle 4 gün arasında değien bir süreçtir. 
                      Süreçte üretilen fotoğraf, dosya ve kayıtlar gibi tüm çıktılar atölye sonrası teslim edilir. 
                  </p>
                  <Link to="/digital-consultancy" className="text-blue">→ İletişime geç</Link>
                  <div className="w-full p-10">
                  <StaticImage src="../images/roadmap.png" alt="Digital Roadmap" />
                  </div>
                  
                </li>
                <li className="w-full lg:w-1/2 p-10 lg:p-20">
                  <h3 className="text-3xl font-bold text-blue py-5">Kurumsal Kimlik</h3>
                  <p className="py-5 max-w-sm font-thin">
                      Dijital görünürlüğün günümüzde bir marka için ne kadar önemli hale geldiğini hepimiz biliyoruz. Daha iyi bir müşteri deneyimi markanızın doğru iletişim kurabilmesiyle başlar. 
                      Markanızın dijitalde en iyi şekilde temsil edildiğinden emin olmak için kurumsal kimlik çalışması yapabiliriz. 
                  </p>
                  <Link to="/digital-consultancy" className="text-blue">→ İletişime geç</Link>
                  <div className="w-full p-10">
                  <StaticImage  src="../images/branding.png" alt="Branding" />
                  </div>
                </li>
                <li className="w-full lg:w-1/2 p-10 lg:p-20">
                  <h3 className="text-3xl font-bold text-blue py-5">UX/UI</h3>
                  <p className="py-5 max-w-sm font-thin">
                      Dokunduğumuz her ürün için klişeleşmiş düşüncelerden uzak keşfe dayalı bir yaklaşımla deneyim geliştiriyoruz. Yeri geldiğinde ürün araştırmasında ürünün her bir noktasını sorgulayan dedektifler, yeri geldiğinde kullanıcının haklarını ve konforunu savunan birer avukat oluyoruz. 
                      Bu hizmetimizde isterseniz kendi ekibinizle ister geliştirici partnerinizle ürünlerinizi geliştirebileceğiniz tüm içerikleri geliştirmeye hazır bir kılavuz olarak hazırlıyoruz. 
                  </p>
                  <Link to="/digital-consultancy" className="text-blue">→ İletişime geç</Link>
                  <div className="w-full p-10">
                  <StaticImage  src="../images/ux.png" alt="UX / UI" />
                  </div>
                </li>
                <li className="w-full lg:w-1/2 p-10 lg:p-20">
                  <h3 className="text-3xl font-bold text-blue py-5">Dijital Ürün Yönetimi</h3>
                  <p className="py-5 max-w-sm font-thin">
                      İyi bir ürünün gerekli tüm kaynak, yetenek ve zamanın iyi kullanımıyla ortaya çıkarılabileceğine inanıyoruz. Bu yüzden dijital ürünlerinizin doğru stratajik planlama ile üretim süreçlerinin doğru planlanması ürünün başarısında çok önemli bir faktör. 
                      Hedeflerinizi gerçekleştirebilmeniz için markanızın ruhuna ve iş kapasitenize uygun olarak stratejiler geliştiriyor ve ürünlerinizin yönetimini yapıyoruz. 
                  </p>
                  <Link to="/digital-consultancy" className="text-blue">→ İletişime geç</Link>
                  <div className="w-full p-10">
                  <StaticImage src="../images/marketing.png" alt="Marketing" />
                  </div>
                </li>
            </ul>
  
        </div>
      <div className="bg-white relative py-10">
        <div className="container text-xl text-center mx-auto  lg:px-10 max-w-5xl">
          <h2 className="text-4xl md:text-5xl lg:text-6xl max-w-3xl p-10 mx-auto font-bold text-blue">
            Markaların dijital partnerleri oluyoruz.
          </h2>
          <p className="font-light">
            İnandığımız projelerden ve partnerlerden bazıları.
          </p>
        </div>
      </div>
      <div className="max-w-6xl mx-auto my-52">
        <ol className="lg:flex flex-wrap list-none">
          {pages.map(page => {
            const title = page.title
            const featuredImage = {
              data: page.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData,
              alt: page.featuredImage?.node?.alt || ``,
            }
            return (
              <li className="w-full lg:w-2/4 p-10" key={page.uri}>
                <article
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header className="py-5">
                    <Link to={page.uri} itemProp="url">
                      {/* if we have a featured image for this page let's display it */}
                      {featuredImage?.data && (
                        <GatsbyImage
                          image={featuredImage.data}
                          alt={featuredImage.alt}
                          style={{ marginBottom: 30 }}
                        />
                      )}
                      <span
                        className="block text-black font-thin text-xl text-left"
                        itemProp="headline"
                      >
                        {parse(title)}
                      </span>
                    </Link>
                  </header>
                  <Link
                    className="block text-black font-thin text-xl text-left underline"
                    to={page.uri}
                  >
                    → Devamını Gör
                  </Link>
                </article>
              </li>
            )
          })}
        </ol>
      </div>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Önceki Sayfa</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Sonraki Sayfa</Link>}
    </Layout>
  )
}

export default CaseIndex

export const pageQuery = graphql`
  query WordPressCaseStudyArchiveTr(
    $offset: Int!
    $postsPerPage: Int!
    $language: String!
  ) {
    allWpPage(
      filter: { language: { slug: { eq: $language } }, categories: {nodes: {elemMatch: {slug: {eq: "case-study-tr"}}}}}
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`
